import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import arrow from 'src/assets/images/icons/ionic-ios-arrow-forward.svg';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default function FullWidthTabs() {
    return (
        <div className="featured-categories">
            <div className="container">
                <div className="block">
                    <h2 className="title title-10">CURADORIA ANAGÊ SELECT</h2>
                    <p className="text text-1">
                        O poder de realizar novas escolhas: inspire-se com nossa seleção de imóveis alto padrão em Joinville
                    </p>
                </div>
                <div className="block-categories">
                    <Swiper
                        spaceBetween={10}
                        slidesPerView={2}
                        breakpoints={{
                            768: {
                                slidesPerView: 5,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <RouterLink
                                to="/apartamentos-selecionados"
                                className="category"
                                style={{ backgroundImage: `url(https://api.anageimoveis.com.br/uploads/medias/suggestion/6329ed943c8d0/6329ed943c8d2.png)` }}
                            >
                                <p className="text text-8">
                                    <img alt="icone" src={arrow} className="icon" />
                                    Apartamentos Selecionados
                                </p>
                            </RouterLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <RouterLink
                                to="/casas-em-condominio"
                                className="category"
                                style={{ backgroundImage: `url(https://api.anageimoveis.com.br/uploads/medias/suggestion/6329ecb97d3bd/6329ecb97d3bf.png)` }}
                            >
                                <p className="text text-8">
                                    <img alt="icone" src={arrow} className="icon" />
                                    Casas em Condomínio
                                </p>
                            </RouterLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <RouterLink
                                to="/coberturas-e-garden"
                                className="category"
                                style={{ backgroundImage: `url(https://api.anageimoveis.com.br/uploads/medias/suggestion/6329ec636ef3a/6329ec636ef3c.png)` }}
                            >
                                <p className="text text-8">
                                    <img alt="icone" src={arrow} className="icon" />
                                    Coberturas e Gardens
                                </p>
                            </RouterLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <RouterLink
                                to="/praia-e-campo"
                                className="category"
                                style={{ backgroundImage: `url(https://api.anageimoveis.com.br/uploads/medias/suggestion/67d85ca1827f2/67d85ca1827f6.png)` }}
                            >
                                <p className="text text-8">
                                    <img alt="icone" src={arrow} className="icon" />
                                    Praia e Campo
                                </p>
                            </RouterLink>
                        </SwiperSlide>
                        <SwiperSlide>
                            <a
                                href="https://lancamentos.anageimoveis.com.br/"
                                className="category"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ backgroundImage: `url(https://api.anageimoveis.com.br/uploads/medias/suggestion/6329ed79ea267/6329ed79ea269.png)` }}
                            >
                                <p className="text text-8">
                                    <img alt="icone" src={arrow} className="icon" />
                                    Lançamentos
                                </p>
                            </a>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    );
}