import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import Slider from './Slider';
import Shops from './Shops';
import Newsletter from 'src/components/AnageImoveis/Newsletter';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getWhatsappLink } from 'src/utils/functions';
import { useAnalytics } from 'src/hooks/useAnalytics';

import image1 from 'src/assets/images/anage-imoveis-imobiliaria-joinville.webp';
import comprarImovelAnage from 'src/assets/images/icons/comprar-imovel-anage.svg';
import anunciarImovelAnage from 'src/assets/images/icons/anunciar-imovel-anage.svg';
import alugarImovelAnage from 'src/assets/images/icons/alugar-imovel-anage.svg';
import reloca from 'src/assets/images/reloca.webp';
import abmi from 'src/assets/images/abmi.webp';


import newimage1 from 'src/assets/images/sobre-anage-select.jpg';
import newimage2 from 'src/assets/images/sobre-icone1.png';
import newimage3 from 'src/assets/images/sobre-icone2.png';
import newimage4 from 'src/assets/images/sobre-icone3.png';
import newimage5 from 'src/assets/images/sobre-atendimento-bg.jpg';


const useStyles = makeStyles(() => ({
    root: {},
    bold: {
      fontWeight: 'bold'
    }
}));

function AboutPage() {
    const classes = useStyles();
    const generalInformation = useSelector((state) => state.interface.generalInformation);

    const { trackEvent } = useAnalytics();

    let pageTitle = 'Sobre a Anagê Imóveis';
    let pageBreadcrumbs = [
        {
            to: '/',
            title: 'Home'
        },
        {
            title: pageTitle
        }
    ];

    return (
        <Page
            className={classes.root}
            title={pageTitle}
            breadcrumbs={pageBreadcrumbs}
        >



            <div className="about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 order-2 order-md-1">
                            <h1>Sobre nós</h1>
                            <h2>
                                Ao seu lado na busca pelo extraordinário
                            </h2>
                            <p>
                                A Anagê Select é a imobiliária de alto padrão referência em Joinville, com mais de 37 anos de mercado e a expertise certa para ajudar quem sonha grande e quer chegar lá.
                            </p>
                        </div>
                        <div className="col-md-8 order-1 order-md-2">
                            <img alt="Imagem sobre nós" src={newimage1} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-differentials-section">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <div className="content">
                                <img alt="Imagem sobre nós" src={newimage2} />
                                <h2>
                                    Exclusividade <br />
                                    no alto padrão
                                </h2>
                                <p>
                                    Trabalhamos apenas com imóveis cuidadosamente selecionados. Avaliamos requisitos como valor de mercado, localização e qualidade construtiva para oferecer o que há de melhor aos nossos clientes.
                                </p>
                            </div>    
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="content">
                                <img alt="Imagem sobre nós" src={newimage3} />
                                <h2>
                                    Experiência  <br />
                                    única
                                </h2>
                                <p>
                                    Na Anagê Select, você tem acesso a um corretor exclusivo, disponível a qualquer momento, uma estrutura diferenciada e uma jornada de atendimento singular, desde o primeiro contato até o pós-venda.
                                </p>
                            </div>    
                        </div>
                        <div className="col-12 col-md-4">
                            <div className="content">
                                <img alt="Imagem sobre nós" src={newimage4} />
                                <h2>
                                    Consultoria  <br />
                                    especializada
                                </h2>
                                <p>
                                    Garantimos uma transação segura, com discrição e transparência em todas as etapas. Estamos preparados para atender suas exigências e te ajudar a atingir todos os seus objetivos, seja para morar ou investir.
                                </p>
                            </div>    
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-service-section">
                <div className="container">
                    <div className="content" style={{ backgroundImage: 'url(' + newimage5 + ')' }}>
                        <div className="row align-items-center">
                            <div className="col-12 col-md-4 offset-md-1">
                                <span className="text1">Atendimento único</span>
                                <h4>
                                    O luxo de uma vida plena começa aqui
                                </h4>

                                <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-1 d-none d-md-inline-block"
                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                                >
                                    Solicitar atendimento
                                </a>
                            </div>
                            <div className="col-12 col-md-4 offset-md-1">
                                <p>
                                    Descubra tudo o que a Anagê Select pode fazer por você. Faça uma busca no site ou converse com um de nossos corretores para saber mais.
                                </p>

                                <a href={getWhatsappLink("47999680934")} target="_blank" className="btn btn-1 d-md-none"
                                    onClick={() => { trackEvent('Clique no botão', 'Whatsapp Geral', '47999680934') }}
                                >
                                    Solicitar atendimento
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Page>
    );
}

export default AboutPage;
