import React, {useState} from 'react';
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import like from 'src/assets/images/icons/heart.png';
import area from 'src/assets/images/icons/login.svg';
import close from 'src/assets/images/icons/close.png';
import logo from 'src/assets/images/logo-anage-calculadora.svg';

import Contact from './Contact.js';
import FavoriteModal from 'src/components/AnageImoveis/FavoriteModal.js';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

export default function TemporaryDrawer({ contactForm = false }) {
    const classes = useStyles();
    const [contactMode, setContactMode] = useState(false);
    const [state, setState] = React.useState({
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div role="presentation">
            {!contactMode ? (
                <>
                    <Button onClick={toggleDrawer(anchor, false)} className="btn-close">
                        <img alt="" src={close} className="icon" />
                    </Button>
                    <div className="hidden-mobile">
                        <List>
                            <ListItem>
                                Imóveis
                            </ListItem>
                            <ListItem>
                                <RouterLink to="/comprar/joinville" onClick={toggleDrawer(anchor, false)}>Comprar</RouterLink>
                            </ListItem>
                            <ListItem>
                                <RouterLink to="/lancamentos/joinville" onClick={toggleDrawer(anchor, false)}>Lançamentos</RouterLink>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/alugar/joinville" target="_blank" onClick={toggleDrawer(anchor, false)}>Alugar</Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/anuncie-seu-imovel" target="_blank" onClick={toggleDrawer(anchor, false)}>Anuncie</Link>
                            </ListItem>
                            <ListItem>
                                <RouterLink to="/imoveis-favoritos" onClick={toggleDrawer(anchor, false)}>Favoritos</RouterLink>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                Institucional
                            </ListItem>
                            <ListItem>
                                <RouterLink to="/institucional" onClick={toggleDrawer(anchor, false)}>Sobre Nós</RouterLink>
                            </ListItem>
                            <ListItem>
                                <Link href="https://anageimoveis.solides.jobs/" target="_blank" onClick={toggleDrawer(anchor, false)}>Trabalhe Conosco</Link>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/joinville" target="_blank" onClick={toggleDrawer(anchor, false)}>Joinville</Link>
                            </ListItem>                            
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/blog" target="_blank" onClick={toggleDrawer(anchor, false)}>Blog</Link>
                            </ListItem>
                        </List>
                    </div>
                    <div className="show-mobile">
                        <List>
                            <ListItem>
                                Imóveis
                            </ListItem>
                            <ListItem>
                                <div className="form-row">
                                    <div className="col-6">
                                        <RouterLink to="/comprar/joinville" onClick={toggleDrawer(anchor, false)}>Comprar</RouterLink>
                                    </div>
                                    <div className="col-6">
                                        <Link href="https://www.anageimoveis.com.br/alugar/joinville" target="_blank" onClick={toggleDrawer(anchor, false)}>Alugar</Link>
                                    </div>
                                </div>
                            </ListItem>
                            <ListItem>
                                <RouterLink to="/lancamentos/joinville" onClick={toggleDrawer(anchor, false)}>Lançamentos</RouterLink>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/anuncie-seu-imovel" target="_blank" onClick={toggleDrawer(anchor, false)}>Anuncie</Link>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                Institucional
                            </ListItem>
                            <ListItem>
                                <div className="form-row">
                                    <div className="col-6">
                                        <RouterLink to="/institucional" onClick={toggleDrawer(anchor, false)}>Sobre Nós</RouterLink>
                                    </div>
                                    <div className="col-6">
                                        <Link href="https://anageimoveis.solides.jobs/" target="_blank" onClick={toggleDrawer(anchor, false)}>Trabalhe Conosco</Link>
                                    </div>
                                </div>
                            </ListItem>
                            <ListItem>
                                <Link href="https://www.anageimoveis.com.br/joinville" target="_blank" onClick={toggleDrawer(anchor, false)}>Joinville</Link>
                            </ListItem>
                            <ListItem>
                                <div className="form-row">
                                    <div className="col-6">
                                        <Link href="https://www.anageimoveis.com.br/blog" target="_blank" onClick={toggleDrawer(anchor, false)}>Blog</Link>
                                    </div>
                                </div>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <Link
                                    href="https://www.anageimoveis.com.br/area-do-cliente"
                                    target="_blank"
                                    className="btn-white"
                                    onClick={toggleDrawer(anchor, false)}
                                >
                                    <img alt="" src={area} className="icon"></img>
                                    Área do Cliente
                                </Link>
                            </ListItem>
                            <ListItem>
                                <RouterLink
                                    to="/imoveis-favoritos"
                                    className="btn-white"
                                    onClick={toggleDrawer(anchor, false)}
                                >
                                    <img alt="" src={like} className="icon icon-like"></img>
                                    Meus Favoritos
                                </RouterLink>
                                <FavoriteModal />
                            </ListItem>
                        </List>
                    </div>
                </>
            ) : (
                <>
                    <Button onClick={ () => setContactMode(!contactMode) } className="btn-close">
                        <img alt="" src={close} className="icon" />
                    </Button>
                    <Contact />
                </>
            )}
        </div>
    );

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <span className="bar bar-1"></span>
                        <span className="bar bar-2"></span>
                        <span className="bar bar-3"></span>
                        <span className="bar bar-4"></span>
                        <span className="bar bar-5"></span>
                        <span className="bar bar-6"></span>
                        <span className="bar bar-7"></span>
                        <span className="bar bar-8"></span>
                        <span className="bar bar-9"></span>
                    </Button>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className={contactMode ? "menu-drawer contact-mode" : "menu-drawer"}>
                        <div className="filter"></div>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
